$HEADER_PADDING: 10px;

.container {
    display: flex;
    align-items: center;
    height: 50px;
    padding-top: $HEADER_PADDING;
    padding-bottom: $HEADER_PADDING;
    font-weight: bold,
}

.logo {
    flex: 1;
    margin-left: 20px;
    text-align: left;
    text-transform: uppercase;
    color: #404040;
    font-size: 30px;
    cursor: pointer;
    font-family: var(--rubik);
}

.logoImg {
    width: 55px;
    vertical-align: middle;
}

.navigation {
    flex: 0;
    font-size: 25px;
}

.navigationLink {
    margin-right: 20px;
    font-family: var(--rubik);

    &:hover {
        color: var(--pink);
    }
}

.disabled {
    color: var(--gray);
    cursor: not-allowed;
}

@media only screen and (max-width: 900px) {
    .logo {
        margin-bottom: 20px;
    }
    .container {
        flex-direction: column;
        margin-bottom: 50px;
    }
    .navigation {
        width: 100%;
    }
  }
