.container {
    display: flex;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    height: 100%;
    perspective: 100em;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.photoContainer {
    display: flex;
    transform: rotateY(-20deg);
    transition: all 0.3s;
}

.photoContainer:hover {
    transform: rotateY(-10deg);
}

.photo {
    flex: 1;
    width: 150px;
    height: 150px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 20px;
    margin-bottom: 10px;
    animation: fadeIn ease 2s;
    box-shadow: var(--shadow);
}
