.content {
  flex: 1;
}

.App {
  color: #404040;
  text-align: center;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.background {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fakeBackground {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 1));
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Dpp-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
