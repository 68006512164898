/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre&display=swap');

:root {
  --pink: #ff644f;
  --light-pink: #ffa194;
  --black: #383838;
  --gray: #dedede;
  --light-gray: #f0f0f0;
  --light-blue: #e6efff;

  --rubik: 'Rubik', sans-serif;
  --frank: 'Frank Ruhl Libre', serif;

  --defaultFontSize: 19px;

  --shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

::selection {
  background: var(--light-pink);
}

body {
  margin: 0;
  color: #383838;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit;
  text-decoration: inherit;
}

li {
  font-family: var(--frank);
}

h1, h2 {
  font-family: var(--rubik);
}

hr {
  border: 1px solid var(--light-gray);
  width: 90%;
  margin-bottom: 30px;
}

p {
  font-size: var(--defaultFontSize);
  font-family: var(--frank);
}