.container {
    flex: 1;

    h2 {
        color: var(--pink);
    }
}

.location {
    font-size: 50px;
}

.description {
    margin: 30px;
}

.comingSoon {
    font-size: 20px;
}