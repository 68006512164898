.container {
    display: flex;
    margin: auto;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
}

.introductionTitle {
    font-size: 50px;
    font-weight: bold;
    font-family: var(--rubik);
    margin-bottom: 10px;
}

.introductionCaption {
    font-size: var(--defaultFontSize);
    font-family: var(--frank);
    margin-bottom: 20px;
    line-height: 1.5;
}

.resumeButton {
    background-color: var(--pink);
    transition: .2s;

    &:hover {
        filter: brightness(120%);
    }

    &:active {
        filter: brightness(98%);
    }
}
.contactMeButton {
    transition: .2s;

    &:hover {
        filter: brightness(102%);
    }

    &:active {
        filter: brightness(98%);
    }
}

@keyframes anvil {
    0% {
      transform: scale(1) translateY(0px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
      transform: scale(0.96) translateY(10px);
      opacity: 0;
      box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
      transform: scale(1) translateY(0px);
      opacity: 1;
      box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
  }

.popupContainer {
    border-radius: 20px;
    opacity: 0.9;
    background: rgba(255, 255, 255, 0.95);
    animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.popupContent {
    padding: 20px;
}

.popupContent p {
    font-family: var(--rubik);
    font-size: 20px;
    font-weight: lighter;
}

.cross {
    float: right;
    font-size: 20px;
    cursor: pointer;
}

.link {
    font-size: 40px;
    color: var(--pink);
    width: 50%;
}

.column {
    flex: 1;
    margin: auto;
}

.introductionContent {
    width: 600px;
    max-width: 100%;
    margin: auto;
    margin-left: 10px;
    margin-right: 10px;
}

.profilePicture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 10px;
    box-shadow: var(--shadow);
    background: linear-gradient(180deg, rgba(255,122,23,1) 0%, rgba(255,100,79,1) 100%, rgba(0,212,255,1) 100%);
}

@media only screen and (max-width: 600px) {
    .popupContainer {
      width: 100%;
    }
  }

  @media only screen and (max-width: 900px) {
    .introductionCollage {
        display: none;
    }
  }