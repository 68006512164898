

.problemContainer {
    font-weight: bold;
    padding: 10px;

    p {
        font-family: var(--rubik);
        margin: 0;
    }
    :hover {
        color: var(--pink);
    }
}

.problemContainer:nth-child(odd) {
    background-color: var(--light-blue);
}

.problemListContainer {
    text-align: left;
}

.codingQuestionsListContainer:nth-child(1) {
    background-color: red;
}

.container {
    width: 100%;
    max-width: 1000px;
    margin: auto;
}
