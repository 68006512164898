
$FOOTER_PADDING: 10px;

.footer {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    min-height: 130px;
    color: #9e9e9e;
    padding-left: $FOOTER_PADDING;
    padding-right: $FOOTER_PADDING;
    padding-top: $FOOTER_PADDING * 2;
    padding-bottom: $FOOTER_PADDING * 2;
    background-color: #f7f7f7;
    align-items: center;
    justify-content: center;
}

.links {
    width: 140px;
    font-size: 30px;
    color: var(--gray);
}
